import { Link } from "react-router-dom";
import { storage } from "../firebase-config";
import { getDownloadURL, ref } from "firebase/storage";

export default function Navbar() {
  SetImage("gs://gymcenter-a60ab.appspot.com/Images/logo.png", "logo");
  return (
    <nav class="bg-white px-4 sm:px-4 flex md:border-b md:border-slate-800 h-24 md:text-center justify-between py-4">
      <Link to="/">
        <img id="logo" class="h-10 absolute md:h-16" />
      </Link>

      <div
        className="w-full md:w-auto block md:flex md:items-center md:block"
        id="navbar"
      >
        <ul class="md:flex block text-right mr-8 md:flex-grow md:flex-grow md:flex">
          <Link to="/">
            <li class="md:mr-15 md:p-6">
              <a
                class="text-xl text-slate-400 hover:text-gray-800 sm:block-inline hover:animate-pulse"
                href="#"
              >
                Forside
              </a>
            </li>
          </Link>
          <Link to="/vision">
            <li class="md:mr-15 md:p-6">
              <a
                class="text-xl text-slate-400 hover:text-gray-800 block sm:block-inline hover:animate-pulse"
                href="#"
              >
                Vision
              </a>
            </li>
          </Link>
          <Link to="/about">
            <li class="md:mr-15 md:p-6">
              <a
                class="text-xl text-slate-400 hover:text-gray-800 sm:block-inline hover:animate-pulse"
                href="#"
              >
                Om os
              </a>
            </li>
          </Link>
        </ul>
      </div>
    </nav>
  );
}
function SetImage(source, id) {
  getDownloadURL(ref(storage, source)).then((url) => {
    let image = document.getElementById(id);
    image.setAttribute("src", url);
  });
}
