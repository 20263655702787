export default function TY() {
  return (
    <div class="text-center pb-20">
      <h1 class="text-xl font-semibold mb-2 text-black justify-center">
        <p>Tusind tak for at besvare vores spørgsmål, det er en kæmpe hjælp.
          <br />
          Hvis du har yderligere spørgsmål eller kommentar, skriv en mail til os på kontakt@gymcenter.dk
        </p>
      </h1>
    </div>
  );
}
