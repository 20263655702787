import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { GymcCenter_app, GymCenter_database } from "../firebase-config";
import { useState, useEffect, useCallback } from "react";
import { addDoc, collection, getDocs } from "firebase/firestore";
import ImageMapper from "react-image-mapper";
import mapDK from "../images/DK_map.png";

export default function Formula() {
  const [newActivity, setNewActivity] = useState({
    Activity_one: false,
    Activity_two: false,
    Activity_three: false,
    Activity_four: false,
    NO_activity: false,
  });
  const [RadioCollection, setNewRadioCollection] = useState({
    q1: "JA",
    q2: "JA",
    q3: "JA",
  });

  const [textArea, setNewText] = useState("");
  const handleChange = (event) => {
    setNewText(event.target.value);
    setCount(event.target.value.length);
  };
  const [count, setCount] = useState(0);
  const [age, setNewAge] = useState(0);
  const [continueGym, setNewContinueGym] = useState();

  const userCollectionRef = collection(GymCenter_database, "lokation");
  useEffect(function renderinfo() {
    const setUsers = async () => {
      const data = await getDocs(userCollectionRef);
      console.log(data);
    };
    setUsers();
  }, []);

  function noChecked() {
    document.getElementById("yes1").checked = false;
    document.getElementById("yes2").checked = false;
    document.getElementById("yes3").checked = false;
    document.getElementById("yes4").checked = false;
  }
  function other_checked() {
    document.getElementById("no").checked = false;
  }
  let navigate = useNavigate();
  async function handle_submit(event) {
    event.preventDefault();
    navigate("tak");
    await addDoc(userCollectionRef, {
      Activity: newActivity,
      interesse: RadioCollection,
      ideer_faciliteter: textArea,
      alder: age,
      fortsat_gymnastik: continueGym,
      kort_value: mapAreas,
    });
    setNewActivity({
      Activity_one: false,
      Activity_two: false,
      Activity_three: false,
      Activity_four: false,
      NO_activity: false,
    });
    setVisible(false), setDisable(true);
  }
  const [visible, setVisible] = useState(true);
  const [disable, setDisable] = useState(false);

  const [query, setQuery] = useState(1);
  const [mapAreas, setMapAreas] = useState({
    name: "my-map",
    areas: [
      { id: 5, shape: "circle", coords: [17, 10, 5], preFillColor: "#000" },
    ],
  });

  const handleUpdateMapArea = useCallback(
    (evt) =>
      updateMapArea(5, [evt.nativeEvent.layerX, evt.nativeEvent.layerY, 5]),
    []
  );
  useEffect(() => {
    setQuery(Math.random());
  }, [mapAreas]);

  const updateMapArea = (id, coords) => {
    console.log(id, coords);
    const areas = mapAreas.areas.map((item) =>
      item.id === id ? { ...item, coords } : item
    );
    setMapAreas({
      name: mapAreas.name,
      areas,
    });
  };

  return (
    <div class="p-8">
      {visible == true && (
        <div class="py-10 container mx-auto px-6 bg-gray-100 max-w-xl">
          <h class="text-2xl font-semibold">Spørgeskema</h>
          <form id="formula" onSubmit={handle_submit} afterSubmit>
            <label class="font-semibold">Alder</label>
            <br />
            <div class="flex justify-center">
              <div class="mb-3 xl:w-96">
                <select
                placeholder="Vælg"
                  onChange={(e) => {
                    setNewAge(e.target.value);
                  }}
                  class="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-outm-0focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-label="Default select example"
                >
                  <option selected disabled>Vælg aldersgruppe</option>
                  <option value={1}>under 16 år</option>
                  <option value={2}>16-19 år</option>
                  <option value={3}>20-24 år</option>
                  <option value={4}>25-29 år</option>
                  <option value={5}>30-39 år</option>
                  <option value={6}>40+</option>
                </select>
              </div>
            </div>
            <br />
            <label class="font-semibold">
              Spørgsmål angående din interesse for/rolle i gymnastik
            </label>
            <br />
            <label>Er du aktiv i gymnastikken? </label>{" "}
            <label class="font-light">
              (Vælg alle relevante svarmuligheder)
            </label>
            <br />
            <input
              id="yes1"
              type="checkbox"
              name="Activity_one"
              value={newActivity.Activity_one}
              onChange={(event) => {
                setNewActivity({
                  ...newActivity,
                  [event.target.name]: event.target.checked,
                  NO_activity: false,
                });
                other_checked();
              }}
            ></input>
            <label> Ja, som gymnast </label>
            <br />
            <input
              id="yes2"
              type="checkbox"
              name="Activity_two"
              value={newActivity.Activity_two}
              onChange={(event) => {
                setNewActivity({
                  ...newActivity,
                  [event.target.name]: event.target.checked,
                  NO_activity: false,
                });
                other_checked();
              }}
            ></input>
            <label> Ja, som instruktør</label>
            <br />
            <input
              id="yes3"
              type="checkbox"
              name="Activity_three"
              value={newActivity.Activity_three}
              onChange={(event) => {
                setNewActivity({
                  ...newActivity,
                  [event.target.name]: event.target.checked,
                  NO_activity: false,
                });
                other_checked();
              }}
            ></input>
            <label> Ja, som bestyrelsesmedlem af en forening </label>
            <br />
            <input
              id="yes4"
              type="checkbox"
              name="Activity_four"
              value={newActivity.Activity_four}
              onChange={(event) => {
                setNewActivity({
                  ...newActivity,
                  [event.target.name]: event.target.checked,
                  NO_activity: false,
                });
                other_checked();
              }}
            ></input>
            <label> Ja, andet </label>
            <br />
            <input
              type="checkbox"
              name="NO_activity"
              id="no"
              value={newActivity.No}
              onChange={(event) => {
                setNewActivity({
                  Activity_one: false,
                  Activity_two: false,
                  Activity_three: false,
                  Activity_four: false,
                  [event.target.name]: event.target.checked,
                });
                noChecked();
              }}
            ></input>
            <label> Nej </label>
            <div class="pt-8">
              <label>
                Har du (fortsat) lyst til at være aktiv i gymnastikken?
              </label>
              <br />
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  name="inlineRadioOptions0"
                  id="inlineRadio1"
                  required
                  value={true}
                  onChange={(e) => {
                    setNewContinueGym(e.target.value);
                  }}
                />
                <label
                  class="form-check-label inline-block text-gray-800"
                  for="inlineRadio10"
                >
                  Ja
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  name="inlineRadioOptions0"
                  id="inlineRadio2"
                  required
                  value={false}
                  onChange={(e) => {
                    setNewContinueGym(e.target.value);
                  }}
                />
                <label
                  class="form-check-label inline-block text-gray-800"
                  for="inlineRadio20"
                >
                  Nej
                </label>
              </div>
            </div>
            <div class="pt-8">
              <label class="font-semibold">
                Spørgsmål angående din interesse for gymcenteret
              </label>
              <div>
                <label>
                  {" "}
                  Vil du have interesse i at have et medlemskab i gymcenteret?{" "}
                </label>
                <br></br>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions1"
                    id="inlineRadio1"
                    required
                    value={RadioCollection.q1}
                    onChange={() => {
                      setNewRadioCollection({ ...RadioCollection, q1: "JA" });
                    }}
                  />
                  <label
                    class="form-check-label inline-block text-gray-800"
                    for="inlineRadio10"
                  >
                    Ja
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions1"
                    id="inlineRadio2"
                    required
                    value={RadioCollection.q1}
                    onChange={() => {
                      setNewRadioCollection({ ...RadioCollection, q1: "NEJ" });
                    }}
                  />
                  <label
                    class="form-check-label inline-block text-gray-800"
                    for="inlineRadio20"
                  >
                    Nej
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions1"
                    id="inlineRadio1"
                    required
                    value={RadioCollection.q1}
                    onChange={() => {
                      setNewRadioCollection({
                        ...RadioCollection,
                        q1: "MÅSKE",
                      });
                    }}
                  />
                  <label
                    class="form-check-label inline-block text-gray-800"
                    for="inlineRadio10"
                  >
                    Måske
                  </label>
                </div>
              </div>
              <label>
                {" "}
                Vil du have interesse i at deltage i workshops i gymcenteret?{" "}
              </label>
              <br></br>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  name="inlineRadioOptions2"
                  id="inlineRadio1"
                  required
                  value={RadioCollection.q2}
                  onChange={() => {
                    setNewRadioCollection({ ...RadioCollection, q2: "JA" });
                  }}
                />
                <label
                  class="form-check-label inline-block text-gray-800"
                  for="inlineRadio10"
                >
                  Ja
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  name="inlineRadioOptions2"
                  required
                  id="inlineRadio2"
                  value={RadioCollection.q2}
                  onChange={() => {
                    setNewRadioCollection({ ...RadioCollection, q2: "NEJ" });
                  }}
                />
                <label
                  class="form-check-label inline-block text-gray-800"
                  for="inlineRadio20"
                >
                  Nej
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  name="inlineRadioOptions2"
                  id="inlineRadio1"
                  required
                  value={RadioCollection.q2}
                  onChange={() => {
                    setNewRadioCollection({ ...RadioCollection, q2: "MÅSKE" });
                  }}
                />
                <label
                  class="form-check-label inline-block text-gray-800"
                  for="inlineRadio10"
                >
                  Måske
                </label>
              </div>
              <label>
                {" "}
                Vil du have interesse for at stå for en workshop i gymcenteret?{" "}
              </label>
              <br />
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  name="inlineRadioOptions3"
                  id="inlineRadio1"
                  required
                  value={RadioCollection.q2}
                  onChange={() => {
                    setNewRadioCollection({ ...RadioCollection, q3: "JA" });
                  }}
                />
                <label
                  class="form-check-label inline-block text-gray-800"
                  for="inlineRadio10"
                >
                  Ja
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  name="inlineRadioOptions3"
                  id="inlineRadio2"
                  required
                  value={RadioCollection.q3}
                  onChange={() => {
                    setNewRadioCollection({ ...RadioCollection, q3: "NEJ" });
                  }}
                />
                <label
                  class="form-check-label inline-block text-gray-800"
                  for="inlineRadio20"
                >
                  Nej
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  name="inlineRadioOptions3"
                  id="inlineRadio1"
                  required
                  value={RadioCollection.q3}
                  onChange={() => {
                    setNewRadioCollection({ ...RadioCollection, q3: "Måske" });
                  }}
                />
                <label
                  class="form-check-label inline-block text-gray-800"
                  for="inlineRadio10"
                >
                  Måske
                </label>
              </div>
            </div>
            <div class="flex pt-8">
              <div class="mb-3 xl:w-96">
                <label class="font-semibold">Ideer til fx faciliteter?</label>
                <textarea
                  class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder={
                    "ekempler: " +
                    "\n rytmesal med spejle på mere end én side," +
                    "\n hal stor nok til 26 meters tilløb til trampolinen"
                  }
                  maxLength={400}
                  value={textArea}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div id="the-count">
                <span id="current">{count}</span>
                <span id="maximum">/ 400</span>
              </div>
            </div>
            <div id="Img_selector" class="pt-8">
              <label class="font-semibold">Ideer til placering?</label>
              <br />
              <p>
                Vi er interesseret i at ligge et sted mellem Aalborg og Viborg.
                Hvor i det område vil du foretrække at gymcenteret ca. bliver
                placeret? Tryk på kortet for at markere det område du gerne vil
                se dette center blive opført.
              </p>
              <br />
              <div className="flex items-center justify-center">
                <ImageMapper
                  src={mapDK}
                  onImageClick={handleUpdateMapArea}
                  map={mapAreas}
                  width={250}
                />
              </div>
            </div>
            <br></br>
            <br></br>
            <div class="p-8">
              <button
                type="submit"
                disabled={disable}
                class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow float-right"
              >
                Indsend
              </button>
            </div>
          </form>
        </div>
      )}
      <Outlet />
    </div>
  );
}
