import React from 'react';
import { Link } from "react-router-dom"
import klit from "../images/Klitgaard.png"
import { storage } from "../firebase-config";
import { getDownloadURL, ref } from "firebase/storage";

export default function Footer() {
  SetImage("gs://gymcenter-a60ab.appspot.com/Images/gymnaster/Klitgaard.png", "klit")
  return (
    <footer class="overflow-hidden relative w-max-9 mx-auto">
      <div class="p-6 bg-gray-100 w-screen">
        <div class="">
          <div class="mb-6">
            <h5 class="uppercase font-bold mb-2.5">Menu</h5>
            <ul class="list-none mb-0">
              <Link to="/">
                <li>
                  <a onClick={() => {
                    const scrollToBottom = () => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    };
                    scrollToBottom()
                  }} href="#!" class="text-black-800">Forside</a>
                </li>
              </Link>
              <Link to="/vision">
                <li>
                  <a onClick={() => {
                    const scrollToBottom = () => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    };
                    scrollToBottom()
                  }} href="#!" class="text-black-800">Vision</a>
                </li>
              </Link>
              <Link to="/about">
                <li>
                  <a onClick={() => {
                    const scrollToBottom = () => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    };
                    scrollToBottom()
                  }} href="#!" class="text-black-800">Om os</a>
                </li>
              </Link>
            </ul>
          </div>

          <div class="mb-6">
            <h5 class="font-bold mb-2 uppercase">Kontakt</h5>

            <p class="mb-4 text-gray-800">
              kontakt@gymcenter.dk
            </p>
          </div>
          <div className='absolute top-6 right-0' >
          <h5 class="text-gray-600 ">Billeder fra:</h5>
          <img className='w-64' id='klit'/>
          </div>
        </div>
      </div>
    </footer>
  );
};

function SetImage(source, id) {
  getDownloadURL(
    ref(storage, source)
  ).then((url) => {
    let image = document.getElementById(id);
    image.setAttribute("src", url);
  });
}