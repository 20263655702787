import React from "react";
import { Link } from "react-router-dom";
import { storage } from "../firebase-config";
import { getDownloadURL, ref } from "firebase/storage";

export default function Home() {
  SetImage("gs://gymcenter-a60ab.appspot.com/Images/gymnaster/home.jpg", "i");
  return (
    <div>
      <div class="bg-gray-100">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
            <h2 class="text-2xl font-extrabold text-gray-900">
              Være med til at gøre vores vision til virkelighed
            </h2>
            <div class="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-6">
              <div class="group relative">
                <div class="relative w-full h-80 bg-white rounded-lg overflow-hidden sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                  <img
                    id="i"
                    alt="Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug."
                    class="w-full h-full object-center object-cover"
                  />
                </div>
              </div>

              <div class="group relative">
                <h3 class="text-sm text-gray-500">Vores vision</h3>
                <p class="text-base font-semibold text-gray-900">
                  Vi bestræber os efter at skabe et gymnastik centrum hvor
                  faciliteterne er i top. Vi ønsker at opbygge et center
                  decideret til gymnastik. Centeret skal bestå af spejlsale, et
                  stort springcenter, plads til opvisning, sovesale og en
                  spisesal. Det er altså ikke et center der er bygget endnu og
                  derfor har i også indflydelse på præcis hvilke faciliteter
                  centeret skal indeholde. For at kunne gøre det til virkelighed
                  har vi brug for jeres input igennem vores spørgeskema eller
                  via vores mail.
                </p>

                <div class="lg:mt-5 lg:p-5">
                  <Link to="/visionANDscroll">
                    <button
                      onClick={() => {
                        const scrollToBottom = () => {
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        };
                        scrollToBottom();
                      }}
                      class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center m-6"
                    >
                      Læs mere her
                    </button>
                  </Link>
                  <Link to="/visionANDscroll">
                    <button
                      onClick={() => {
                        const scrollToBottom = () => {
                          window.scrollBy(0, window.innerHeight);
                        };
                        scrollToBottom();
                      }}
                      class="ml-5 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                    >
                      Gå til spørgeskema
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none "></div>
    </div>
  );
}
function SetImage(source, id) {
  getDownloadURL(ref(storage, source)).then((url) => {
    let image = document.getElementById(id);
    image.setAttribute("src", url);
  });
}
