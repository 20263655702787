// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const SECRET_API = process.env.REACT_APP_SECRET_API_KEY;
const AUTH_API = process.env.REACT_APP_AUTH_KEY;
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later. Made for connect to our firebase project
const firebaseConfig = {
  apiKey: SECRET_API,
  authDomain: AUTH_API,
  projectId: "gymcenter-a60ab",
  storageBucket: "gymcenter-a60ab.appspot.com",
  messagingSenderId: "967893266047",
  appId: "1:967893266047:web:5c1392e93457a02256194a",
  measurementId: "G-RP8143TMVK",
};

// Initialize Firebase
export const GymcCenter_app = initializeApp(firebaseConfig);

// make the firebase app connect to the firestore
export const GymCenter_database = getFirestore(GymcCenter_app);
export const storage = getStorage(GymcCenter_app);
