export default function Hero() {
    return (
        <div class="py-20">
            <div class="container mx-auto px-6">
                <h1 class="text-3xl font-bold mb-2 text-black">
                    Velkommen!
                </h1>
                <h2 class="text-xl mb-8 text-gray-700">
                    Vi er en gruppe gymnaster med en drøm om at opføre et center til gymnastik for unge og voksne med plads til at lege, øve og nørde med gymnastik. Vi ønsker at holde åbne træninger og give gymnaster adgang til faciliteterne året rundt, fremfor at oprette hold der bruger en sæson på at lave et opvisningsprogram. Vi ønsker at Gymcenteret skal skabe glæde, fællesskab og læring.
                </h2>
            </div>
        </div>
    );
}