import { storage } from "../firebase-config";
import { getDownloadURL, ref } from "firebase/storage";

export default function About() {
    SetImage("gs://gymcenter-a60ab.appspot.com/Images/gymnaster/Profil_kenneth.jpg", "kenneth")
    SetImage("gs://gymcenter-a60ab.appspot.com/Images/gymnaster/Profil_maria.png", "maria")
    SetImage("gs://gymcenter-a60ab.appspot.com/Images/gymnaster/Profil_thomas.png", "thomas")
    SetImage("gs://gymcenter-a60ab.appspot.com/Images/gymnaster/placeholder.png", "benne")
  return (
    <div>
      <div class="p-8">
        <div className="container mx-auto px-6">
          <h1 className="text-3xl font-bold mb-1 text-black">Om os</h1>
          <h2 class="text-xl mb-8 text-gray-700">
            Udviklerne bag projektet er et samarbejde blandt venner med
            forskellige faglige baggrunde som er med til at bidrage til
            opsætningen, visualiseringen og idégenereringen af centeret. Alle
            udviklerene er aktive gymnaster. Centeret bliver altså lavet af
            gymnaster for gymnaster. Alt arbejde på eller omkring GymCenter er lavet af frivillige.
          </h2>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 pt-10 pb-20 pl-20 bg-white grid">
          <div className="w-full p-4">
            <div class="h-64 w-64 overflow-hidden rounded-3xl ">
              <img id="benne" className=" pb-1" />
            </div>
            <h3 className="text-2xl text-black font-bold">Benedikte Nylev</h3>
            <h2 className="text-xl text-black">Leder</h2>
          </div>

          <div className="w-full p-4">
            <div class="h-64 w-64 overflow-hidden rounded-3xl">
              <img id="maria" className=" pb-1" />
            </div>
            <h3 className="text-2xl text-black font-bold">Maria Christensen</h3>
            <h2 className="text-xl text-black">Næstkommanderende</h2>
          </div>

          <div className="w-full p-4">
            <div class="h-64 w-64 overflow-hidden rounded-3xl">
              <img id="kenneth" className=" pb-1" />
            </div>
            <h3 className="text-2xl text-black font-bold">Kenneth Køpke</h3>
            <h2 className="text-xl text-black">Back-end mester</h2>
          </div>

          <div className="w-full p-4">
            <div class="h-64 w-64 overflow-hidden rounded-3xl">
              <img id="thomas" className=" pb-1" />
            </div>
            <h3 className="text-2xl text-black font-bold">Thomas Larsen</h3>
            <h2 className="text-xl text-black">Front-end mand</h2>
          </div>
        </div>
      </div>
      <div class="p-8">
        <div className="container mx-auto px-6">
          <h1 className="text-3xl font-bold mb-1 text-black">Tak</h1>
          <h2 class="text-xl mb-8 text-gray-700">
            Vi vil gerne sige tusind tak for de gode gymnastik billeder som er
            taget af Mads Klitgaard Olesen/Klitgaard Media. Desuden en tak til
            gymnasterne på Nordjyllands rephold 21/22, som har leget modeller.
          </h2>
        </div>
      </div>
    </div>
  );
}
function SetImage(source, id) {
    getDownloadURL(ref(storage, source)).then((url) => {
      let image = document.getElementById(id);
      image.setAttribute("src", url);
    });
  }