import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { GymcCenter_app, GymCenter_database } from "./firebase-config";
import Navbar from "./components/Navbar.js";
import Hero from "./components/hero.js";
import Footer from "./components/footer.js";
import Home_page from "./pages/home.js";
import Vision from "./pages/vision.js";
import About from "./pages/about.js";
import FORM from "./components/form.js";
import TY from "./pages/thankyou.js";
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const Home = () => (
    <div>
        <Hero />
        <Home_page />
    </div>
);

export default function App() {
    return (
        <div className="App">
            <Navbar />
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="vision" element={<Vision />}>
                    <Route path="skema" element={<FORM />}>
                        <Route path="tak" element={<TY />} />
                    </Route>
                </Route>
                <Route path="about" element={<About />} />
                <Route path="visionANDscroll" element={<Vision />}>
                    <Route path="skema" element={<FORM />}>
                        <Route path="tak" element={<TY />} />
                    </Route>
                </Route>
            </Routes>
            <Footer />
        </div>
    );
}
