import { Routes, Route, Link, Outlet } from "react-router-dom";
import Data_form from "../components/form.js";
import visionImg4 from "../images/VisionImg4.jpg";
import visionImg5 from "../images/VisionImg5.jpg";
import visionImg6 from "../images/VisionImg6.jpg";
import visionImg7 from "../images/VisionImg7.jpg";
import { storage } from "../firebase-config";
import { getDownloadURL, ref } from "firebase/storage";

export default function Vision() {
  SetImage("gs://gymcenter-a60ab.appspot.com/Images/gymnaster/VisionImg4.jpg", "img4")
  SetImage("gs://gymcenter-a60ab.appspot.com/Images/gymnaster/VisionImg5.jpg", "img5")
  SetImage("gs://gymcenter-a60ab.appspot.com/Images/gymnaster/VisionImg6.jpg", "img6")
  SetImage("gs://gymcenter-a60ab.appspot.com/Images/gymnaster/VisionImg7.jpg", "img7")
  return (
    <div>
      <div class="py-20">
        <div class="container mx-auto">
          <h1 class="text-3xl font-bold mb-2 text-black">Vores vision</h1>
          <h2 class="text-xl mb-8 text-gray-700">
            Vi bestræber os efter at skabe et gymnastik centrum hvor faciliteterne er i top. Ideen er kommet af at vi som gymnaster har savnet nogle faciliteter i de haller vi selv har trænet. Manglerne kommer ofte af at hallerne er lavet til forskellige sportsgrene. Derfor ønsker vi at opbygge et center decideret til gymnastik. Vi håber på at centeret i hvert fald kan komme til at bestå af; spejlsale, et stort springcenter, plads til opvisning, sovesale og en spisesal.
            </h2><h2 class="hidden">
            Uddybning af tekst om faciliteterne
          </h2>
        </div>
      </div>

      <div class="container mx-auto">
        <div class="grid-cols-5 space-y-2 bg-white lg:space-y-0 lg:grid lg:gap-3 lg:grid-rows-5">
          <div class="w-full rounded col-span-3">
            <img id="img4" />
          </div>

          <div class="w-full col-span-2 rounded p-6">
            <h3 class="text-2xl text-black font-bold text-center pb-4">
              Åben træning
            </h3>
            <p class="text-lg  text-black">
              Vi vil tilbyde åbne træninger hvor man har muligheder for at øve
              og lege med det man har lyst til.
              <br /><br />
              Springcenteret kan holdes åbent, hvis der er en person tilstede med modtagerkursus. Gymcenteret håber på at have frivillige tilknyttet, så der kan holdes åbent hver uge. Desuden kan man som medlem oprettes som havende modtagerkursus.
              <br /><br />
              Spejlsalene vil kunne benyttes så længe de ikke er booket.
            </p>
          </div>

          <div class="w-full col-span-2 rounded p-6">
            <h3 class="text-2xl text-black font-bold text-center pb-4">Workshop</h3>
            <p class="text-lg  text-black">
              Vi ønsker at skabe et fokus på læring af hinanden. Derfor ønsker
              vi at Gymcenteret skal ligge lokaler til workshops. Ideen er at
              alle der har lyst, kan lave en workshop i samarbejde med
              Gymcenter. Derudover ønsker vi at opsøge fx. dygtige instruktører,
              dansere og verdensholdsgymnaster til at lave workshops. Det kan
              være spring, rytme og dans.
            </p>
          </div>

          <div class="w-full rounded col-span-3">
            <img id="img5" />
          </div>
          <div class="w-full rounded col-span-3">
            <img id="img6" />
          </div>
          <div class="w-full col-span-2 rounded p-6">
            <h3 class="text-2xl text-black font-bold text-center pb-4">
              Weekendtræning
            </h3>
            <p class="text-lg  text-black">
              Weekenderne kan blive booket af voksenhold som står og mangler et
              sted at være til deres weekendtræninger. Centeret vil kunne
              tilbyde alt hvad der vil blive brug for til en weekendtræninger;
              gymnastikfaciliteter såvel som spise- og soverum.
              <br /><br />
              En ide der har været oppe og vende er også at lade efterskoler booke sig ind for en dag. Dette er mest tiltænkt efterskoler med gymnstik på programmet, men som ikke har det helt store springcenter. Vi vil altså gerne sørge for at eleverne for en smag på hvad gymnastik også kan være.
            </p>
          </div>
          <div class="w-full col-span-3 rounded p-6">
            <h3 class="text-2xl text-black font-bold text-center pb-4">
              Stævner/opvisninger
            </h3>
            <p class="text-lg  text-black">
              Vi ønsker at have et opvisningsgulv eller en hal decideret til det (arena lignende), så vi kan arrangere opvisningsdage og/eller gymnastikstævner med fest om aftenen.
            </p>
          </div>
          <div class="w-full rounded col-span-2">
            <img id="img7" />
          </div>

          <div class="w-full col-span-5 rounded p-6">
            <h3 class="text-2xl text-black font-bold text-center pb-4">
              Medlemsskab og bookning
            </h3>
            <p class="text-lg  text-black">
              Der vil blive sat et system op hvor man som medlemmer kan tjekke ledigheden af lokaler. Formenetlig bliver det sat op på denne hjemmeside såvel som en app, så det altid er nemt at tjekke.
            </p>
            <h2 class="pt-6 text-xl text-black font-bold text-center">
              Medlem
            </h2>
            <p class="text-lg  text-black">
              Som medlem kan du komme og gå stort set som du vil. Et medlemsskab kan betales på årsbasis eller månedsbasis. Det vil også være muligt at komme i centeret på en dagsbillet. 
            </p>
            <p class="hidden">
              Herunder ses en tabel med mulige priser, bemærk at intet er fastsat.
            </p>
            <h2 class="pt-6 text-xl text-black font-bold text-center">
              Booking som hold eller efterskole
            </h2>
            <p class="text-lg  text-black">
              Når i booker som hold eller efterskole kan i vælge hvor mange af faciliteterne i vil gøre brug af. Desuden vil Gymcenteret have personale, som står klar til at hjælpe med bookingen, så alle detaljer nemt kommer på plads.
            </p>
            <h2 class="pt-6 text-xl text-black font-bold text-center">
              Tilmelding til workshops
            </h2>
            <p class="text-lg  text-black">
              Tilmelding til workshops kan komme til at foregå i Gymcenterets system. Hvis workshoppen bliver arrangeret eksternt, hvor de bare booker sig ind i lokalerne, kan tilmedlingen også komme til at gå direkte gennem dem.
            </p>
          </div>
        </div>
      </div>
      <div class="container mx-auto px-6">
        <h class="text-2xl font-semibold" id="skema">
          Spørgeskema
        </h>
        <p class="pb-8">
          For at kunne lave et center i den her kaliber er der behov for støtte. For at få den støtte skal vi have noget data der viser at der faktisk er interesse for at bygge sådan et center. Derfor vil vi sætte pris på at i svare på dette korte spørgeskema.
        </p>
      </div>

      <div class="text-center pb-20">
        <Link to="skema">
          <button id="b" class="bg-transparent hover:bg-gray-400 text-black font-semibold hover:text-white py-2 px-4 border border-gray-400 rounded ">
            Besvar spørgeskema
          </button>
        </Link>
      </div>
      <Outlet />
    </div>
  );
}
function SetImage(source, id) {
  getDownloadURL(ref(storage, source)).then((url) => {
    let image = document.getElementById(id);
    image.setAttribute("src", url);
  });
}